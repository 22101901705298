.app {
  height: 100%;
  width: 100%;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.error {
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  line-height: 20px;
}

button.button {
  position: absolute;
  bottom: 180px;
  left: 0;
  left: 0;
  right: 0;
  width: 80%;
  height: 48px;
  margin: 0 auto;
}
